$dark-blue: #4b3a85;
$light-blue: #2bc3cc;
$mid-blue: #6c53bf;
$white: #FFF;
$black: #000000;
$red: #f66271;
$silver: #dbdbdb;
$light-silver: #f2f2f2;
$dark-silver: #a9acb4;
$off-white: #fefefe;
$light-purple: #ddd6f2;
$light-yellow: #FDE67B;
$ultra-blue: #249db0;
$green-text: #199f8f;
$main-color-ligh: #816cc8;

html,body {
    /*font-family: "GessMedium" */
    font-family: 'Almarai', sans-serif;
    direction: rtl;
    height: 100%; }
/*General styles */
a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
    outline: none; }
.form-control:focus, .form-control:focus, .form-control:hover {
    outline: 0;
    box-shadow: none;
    border-color: $silver; }
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus, button.active.focus, button.active:focus, button.focus, button:active.focus, button:active:focus, button:focus {
    outline: 0;
    box-shadow: none; }
p {
    word-break: break-all; }
.br-5 {
    border-radius: 5px !important; }
.br-20 {
    border-radius: 30px !important; }
.w-40 {
    width: 40% !important; }
.w-20 {
    width: 20% !important; }
.w-35 {
    width: 35%; }
.w-70 {
    width: 70%; }
.w-60 {
    width: 60%; }
.w-15 {
    width: 15%; }
.w-90 {
    width: 90%; }
.w-10 {
    width: 10%; }
.h-55 {
    height: 55px; }
.mh-55 {
    min-height: 55px; }
.h-45 {
    height: 45px; }
.h-40 {
    min-height: 40px; }
.h-80 {
    height: 80px; }
.h-65 {
    height: 65px; }
.clickable {
    cursor: pointer; }
.br-0 {
    border-radius: 0px !important; }
.table td, .table th {
    vertical-align: middle; }
.radius-top-0 {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important; }
.radius-bottom-0 {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important; }
.left-radius-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
.left-radius-5 {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; }
.en-text {
    font-family: MontserratMedium; }
.ar-text {
    font-family: "GessMedium"; }
.bickham-text {
    font-family: "BickhamRegular"; }
.bustan-text {
    font-family: "BustanBold"; }
.sans-text {
    font-family: "TheSansArabBold"; }
.popover {
    font-family: "GessMedium"; }
.dark-text {
    color: $dark-blue !important; }
.line-through-text {
    text-decoration: line-through; }
.br-left-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
.justify-content-evenly {
    justify-content: space-evenly; }
.word-break {
    word-break: break-word; }
.pre-wrap {
    white-space: pre-wrap; }
.keep-all {
    word-break: keep-all; }
.light-text {
    color: $light-blue; }
.ultra-text {
    color: $ultra-blue; }
.red-text {
    color: $red; }
.red-bg {
    background-color: $red; }
.mid-text {
    color: $mid-blue !important; }
.dark-silver-text {
    color: $dark-silver; }
.silver-text {
    color: $silver; }
.light-silver-text {
    color: #fbfbfb; }
.green-text {
    color: #109201; }
.ultra-green-text {
    color: $green-text; }
.dark-bg {
    background: $dark-blue; }
.mid-bg {
    background: $mid-blue; }
.light-bg {
    background-color: $light-blue !important; }
.silver-bg {
    background-color: $light-silver !important; }
.light-purple-text {
    color: $light-purple; }
.off-white-bg {
    background-color: $off-white; }
//.light-font-text
//    font-family: GessLight
.cover-img {
    object-fit: cover;
    object-position: left; }
.contain-img {
    object-fit: contain; }
.smaller {
    font-size: 65% !important; }
.font-16 {
    font-size: 16px; }
.light-border {
    border: 1px solid $light-blue !important; }
.white-border {
    border: 1px solid $white !important; }
.text-break {
    word-break: break-word; }
.ltr-dir {
    direction: ltr; }
.underlined-tabs {
    li {
        width: 100px;
        text-align: center; }
    li>a.active {
        color: $light-blue; }
    li>a:after {
        display: block;
        content: "";
        border-bottom: 2px solid $light-blue;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        transform-origin: 100% 50%;
        margin: 0 auto;
        margin-top: 5px;
        width: 85%; }
    li>a:hover:after, li>a.active:after {
        transform: scaleX(1);
        ransform-origin: 0 50%; } }

form {
    .input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px; }
    input.form-control::placeholder, textarea.form-control::placeholder {
        color: $silver;
        font-size: 12px; }
    input.form-control {
        color: $dark-blue;
        height: 40px;
        border-radius: 5px;
        border: 1px solid $silver;
        font-size: 13px; }
    select.form-control {
        color: $silver;
        height: 40px;
        border-radius: 5px;
        border: 1px solid $silver;
        font-size: 12px; }
    .input-group-text {
        background-color: $white;
        border: 1px solid $silver;
        height: 40px;
        svg {
            color: $silver;
            font-size: 18px; } }
    input.ltr-input {
        direction: ltr;
        font-family: MontserratMedium; }
    input.ltr-input::-webkit-input-placeholder {
        direction: rtl;
        font-family: GessMedium; }
    input.en-input::-webkit-input-placeholder {
        font-family: MontserratMedium;
        direction: ltr;
        text-align: center; }
    .form-control[readonly] {
        background-color: $white; }
    .form-control:disabled, .form-control[readonly] {
        color: #a9acb4; }
    small {
        color: $red;
        font-size: 10px;
        padding-top: 5px; }
    .input-error>.input-group-prepend>.input-group-text, .input-error>input.form-control, .input-error>textarea.form-control, .input-error>.intl-tel-input>input, .input-error>select.form-control, .input-error>.rdt>input.form-control {
        border: 1px solid $red;
        svg {
            color: $red; } }
    .input-success>.input-group-prepend>.input-group-text, .input-success>input.form-control, .input-success>textarea.form-control, .input-success>.intl-tel-input>input, .input-success>select.form-control, .input-success>.rdt>input.form-control {
        border: 1px solid $light-blue;
        color: $dark-blue;
        svg {
            color: $light-blue; } } }

input {
    &[type='number'] {
        -moz-appearance: textfield; }
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none; } }
form.centered {
    margin: 0 auto;
    width: 50%; }
a.btn {
    line-height: 25px; }
.dark-outline-btn {
    border-radius: 50px;
    border: 1px solid $dark-blue;
    color: $dark-blue;
    height: 40px;
    font-size: 13px;
    &:hover, &:focus {
        background-color: $dark-blue;
        color: $white;
        transition: background-color ease-out 0.3s; } }
.dark-btn {
    border-radius: 50px;
    border: 1px solid $dark-blue;
    color: $white;
    height: 40px;
    font-size: 13px;
    background-color: $dark-blue;
    &:hover, &:focus {
        background-color: $white;
        border: 1px solid $dark-blue;
        color: $dark-blue; } }
.light-btn {
    border: 1px solid $light-blue;
    color: $white;
    font-size: 12px;
    background-color: $light-blue;
    &:hover, &:focus {
        background-color: $white;
        border: 1px solid $light-blue;
        color: $light-blue; } }
.light-outline-btn {
    border-radius: 50px;
    border: 1px solid $light-blue;
    color: $light-blue;
    height: 40px;
    font-size: 13px;
    &:hover, &:focus {
        background-color: $light-blue;
        color: $white;
        transition: background-color ease-out 0.3s; } }
.blue-border-btn {
    border: 1px solid $light-blue;
    color: $light-blue;
    font-size: 11px;
    &:hover, &:focus {
        background-color: $light-blue;
        color: $white;
        transition: background-color ease-out 0.3s; } }
.red-border-btn {
    border: 1px solid $red;
    color: $red;
    font-size: 11px;
    &:hover, &:focus {
        background-color: $red;
        color: $white;
        transition: background-color ease-out 0.3s; } }
.silver-outline-btn {
    border-radius: 50px;
    border: 1px solid #fbfbfb;
    color: #fbfbfb;
    height: 40px;
    font-size: 13px;
    &:hover, &:focus {
        background-color: #fbfbfb;
        color: $dark-blue;
        transition: background-color ease-out 0.3s; } }
.red-outline-btn {
    border: 1px solid $red;
    color: $red;
    font-size: 13px;
    cursor: pointer; }
.yellow-btn {
    border-radius: 50px;
    border: 1px solid $light-yellow;
    color: $dark-blue;
    background-color: $light-yellow;
    height: 45px;
    line-height: 2 !important;
    &:hover, &:focus {
        background-color: $white;
        border: 1px solid $light-yellow;
        color: $dark-blue; }
    &.btn-width {
        min-width: 260px; } }
.blue-btn {
    border-radius: 80px;
    border: 1px solid $light-blue;
    color: $dark-blue;
    height: 100%;
    font-size: 13px;
    background-color: $light-blue;
    &:hover, &:focus {
        background-color: $light-blue;
        border: 1px solid $light-blue;
        color: $dark-blue; } }
.light-blue-btn {
    background-color: $light-blue;
    border-color: $light-blue;
    width: 100%;
    margin-bottom: 4%;
    &:hover, &:focus {
        background-color: $light-blue;
        border: 1px solid $light-blue;
        color: $white; } }
.intl-tel-input {
    direction: ltr;
    width: 100%;
    font-family: MontserratMedium;
    .country-list {
        z-index: 12; } }
.phone-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    align-self: center;
    background-color: #9786d2;
    bottom: 20px; }
.react-code-input {
    direction: ltr;
    input {
        font-family: MontserratMedium !important;
        box-shadow: none !important;
        outline: 0 !important;
        text-align: center !important;
        color: $dark-blue !important;
        width: 36px !important;
        height: 36px !important;
        font-size: 18px !important;
        padding-left: 0px !important;
        margin: 4px !important;
        border-radius: 4px !important; } }
.vertical-separator {
    height: 25px;
    background-color: #dbdbdb;
    width: 1px;
    margin-right: 10px;
    margin-left: 10px; }

.swal-title, .swal-text {
    color: $dark-blue; }
.swal-footer {
    text-align: center; }
.custom-swal-btn {
    background-color: #7cd1f9;
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 24px;
    margin: 0;
    cursor: pointer; }
.half-circle-border {
    width: 150px;
    height: 75px;
    border-top-left-radius: 85px;
    border-top-right-radius: 85px;
    border: 5px solid $light-silver;
    border-bottom: 0; }
.full-circle-border {
    width: 55px;
    height: 55px;
    border-radius: 100%;
    border: 5px solid $light-silver;
    display: flex;
    align-items: center;
    justify-content: center; }
.gradient-bg {
    width: 70px;
    height: 70px;
    opacity: 0.7;
    border-radius: 13.5px;
    background-image: linear-gradient(145deg, #2bc3cc, #6141cc); }
.silver-box-bg {
    width: 70px;
    height: 70px;
    border-radius: 13.5px;
    background-color: #fbfbfb; }
.gray-box-border {
    border: 1px solid #434343 !important; }
.unset-height {
    height: unset !important; }
.unset-line-height {
    line-height: unset !important; }
.box-height {
    min-height: 400px; }
.btn-radius {
    border-radius: .2rem !important; }
.box-layout {
    border: 1px solid $silver;
    border-radius: 5px;
    background-color: $white; }
.question-box-layout {
    border: 1px solid #2bc3cc;
    border-radius: 20px;
    background-color: #2bc3cc; }
.categories-box-layout {
    border: 1px solid #2bc3cc;
    border-radius: 20px;
    height: 250px; }
.initiative-categories-box-layout {
    border: 1px solid #2bc3cc;
    border-radius: 20px;
    min-height: 250px;
    padding: 5px; }

.general-box-layout {
    border: 3px solid #2bc3cc;
    border-radius: 20px;
    padding: 10px; }


.slick-prev:before, .slick-next:before {
    color: $dark-blue; }
.slick-list > .slick-track {
    margin-right: 0; }
.ball-clip-rotate > div {
    margin: 0px; }
.intl-tel-input .selected-flag:focus {
    outline: 0;
    box-shadow: none; }
.disabled-input {
    .input-group-prepend {
        .input-group-text {
            background-color: #e9ecef; } } }
.rdtOpen .rdtPicker {
    font-family: MontserratRegular; }
.rdt {
    direction: ltr;
    input {
        direction: ltr;
        font-family: MontserratRegular; } }
.swal-text {
    text-align: center; }
.accordion__title:hover {
    transition: background-color ease-out 0.3s; }
.btn-width {
    width: 120px; }
@media print {}
@page {
    size: auto;
    margin: 0mm; }

@media only screen and (max-width: 767px) {
    .btn-width {
        width: unset; }
    .yellow-btn.btn-width {
        min-width: 100%; }
    .w-25, .w-75, .w-40, .w-35 {
        width: 100% !important; }
    .navbar-light .navbar-toggler {
        border-color: #dfdfdf; }
    .hero-section {
        margin-top: 50px;
        margin-bottom: 25px;
        h2 {
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 25px; } }
    .testimonials-section {
        padding-top: 60px;
        margin-bottom: 70px; }
    .categories-section {
        padding-top: 0px;
        background-size: 50%; }
    .section-padder {
        padding-top: 50px;
        padding-bottom: 30px; }
    .custom-tag {
        min-height: 220px !important; }
    .slick-prev, .slick-next {
        display: none !important; }
    form.centered {
        width: 70%; }
    .artwork-img {
        height: unset;
        width: 100%; } }
.initiative-main-title-layout {
    border: 1px solid #2bc3cc;
    border-radius: 70px;
    min-height: 140px;
    width: 100%;
    margin-bottom: 20px;
    background-image: linear-gradient(to bottom, #79ccda, #8a9cd2);
    h5, h6, h3 {
        text-align: center !important;
        margin-top: 20 !important;
        color: $white !important; } }
.label-btn {
    background-color: #4A3985;
    border: 1px solid #4A3985;
    border-radius: 50px;
    color: white;
    &:hover, &:focus {
        background-color: #4A3985;
        border: 1px solid $white;
        color: $white; } }
.lecture-box-layout {
    border: 1px solid #2bc3cc;
    border-radius: 20px;
    height: 150px;
    margin-bottom: 40px; }
.share-label {
    background-color: #FDE67B;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    margin-left: 20px;
    img {
        object-fit: contain;
        height: 23px;
        width: 100%; } }

.font-size-40 {
    font-size: 40px; }

.center {
    position: absolute;
    bottom: 8px;
    text-align: center;
    font-size: 18px; }

.bottom-left {
    position: absolute;
    bottom: -109px;
    left: 62px;
    .center {} }
.center-right {
    position: absolute;
    right: 425px;
    top: 0px; }

.yellow-div {
    border-radius: 50px;
    border: 1px solid $light-yellow;
    color: $dark-blue;
    height: 40px;
    background-color: $light-yellow; }

.crossed-line {
    text-decoration: line-through; }
