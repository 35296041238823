
.btn-yellow{
    color: #816cc8 !important;
    width: auto !important;
    line-height: normal;
    display: flex;
    align-items: center;
}
.btn-yellow::hover{
    color: #fff !important;
}
.modal-container{
    padding: 3px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.box-layout{
    flex: 1;
    width: 100%;
}

/* .iframe-container{
    width: 100%;
    height: 100%;
  } */
  .iframe-container iframe{
    width: 100%;
    height: 100%;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 43%;
    position: relative;
    height: 0;
    width: 100%;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border: 1px solid #dbdbdb;
  }

