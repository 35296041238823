.loading-screen{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: pulse 1s cubic-bezier(0.075, 0.82, 0.165, 1) alternate infinite;
}

@keyframes pulse {
    from { 
        transform: scale(1);
    }
    to{
        transform: scale(1.8);
    }
}