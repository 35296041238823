.cartImage-container{
    width: 30px;
    height: 25px;
    position: relative;
    display: inline-block;
    /* display: none; */
}
.cartImage-container img {
    width: 100%;
    height: 100%;
}
                   
.cartItems-count { 
    width: 25px;
    height: 25px;
    border-radius: 50%;
    /* background-color: #d7d7d7; */
    background-color: #ff6575;
    position: absolute;
    top: -10px;
    right: -10px;
    /* color: #4b3a85; */
    color: #fff;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* vertical-align: cen; */
    /* display: none; */
}

.ball-beat > div{
    width: 5px !important;
    height: 5px !important;
    margin: 1px !important;
}